/* eslint-disable no-mixed-operators */
function calculerInteretsComposes(capitalInitial, epargneMensuelle, tauxInteretAnnuel, horizonPlacement, intervalleVersement) {
  // Conversion du taux d'intérêt annuel en décimal
  const tauxInteret = tauxInteretAnnuel / 100;

  // Nombre de périodes dans l'année (1 pour annuel, 12 pour mensuel, etc.)
  const periodesParAn = 12 / intervalleVersement;

  // Taux d'intérêt par période
  const tauxInteretParPeriode = tauxInteret / periodesParAn;

  // Nombre total de périodes
  const nombreTotalPeriodes = horizonPlacement * periodesParAn;

  // Calcul du capital final avec intérêts composés (capital initial uniquement)
  const capitalAvecInterets = capitalInitial * (1 + tauxInteretParPeriode) ** nombreTotalPeriodes;

  // Calcul des versements périodiques avec la formule analytique
  let totalVersement = 0;
  if (epargneMensuelle > 0) {
    const facteur = (1 + tauxInteretParPeriode) ** nombreTotalPeriodes;
    totalVersement = (epargneMensuelle * intervalleVersement) * (facteur - 1) / tauxInteretParPeriode;
  }

  // Somme du capital initial et des versements avec intérêts composés
  const capitalFinal = capitalAvecInterets + totalVersement;

  // Calcul des intérêts totaux
  const totalInterets = capitalFinal - capitalInitial - (epargneMensuelle * 12 * horizonPlacement);

  const totalVersements = capitalFinal - totalInterets;

  // Retourner les détails
  return {
    capitalFinal: Math.round(capitalFinal * 100) / 100,
    totalVersements: Math.round(totalVersements * 100) / 100,
    totalInterets: Math.round(totalInterets * 100) / 100,
  };
}

export default calculerInteretsComposes;
