/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
  Button, CardActions, TextField,
} from '@mui/material';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import './calculetteImmo.scss';
import pretBanqueImg from '../../img/pret.jpg';
import notaireImg from '../../img/notaire.webp';
import cup from '../../img/greenPlanet.jpg';
import interetsComposesImg from '../../img/interetsComposesImg.jpg';
import getMensualite from '../../Utils/CalculMensualite';
import getCapaciteEmprunt from '../../Utils/CalculCapaciteEmprunt';
import { requestTauxInteret } from '../../Request/RequestTauxImmoBanDeFrance';
import RadioButtonsGroup from '../../Utils/RadioButtonsGroup';
import getFraisDeNotaire from '../../Utils/CalculFraisDeNotaire';
import calculerInteretsComposes from '../CardInteretCompose/CardInteretCompose';

import Loader from '../Loader/Loader';

function CalculetteImmo({ className, ...rest }) {
  const [onClickMensualite, setOnClickMensualite] = useState(false);
  const [onClickPrixDuBien, setOnClickPrixDuBien] = useState(false);
  const [capital, setCapital] = useState();
  const [taux, setTaux] = useState();
  const [duree, setDuree] = useState();
  const [mensualite, setMensualite] = useState();
  const [salaire, setSalaire] = useState();
  const [autreEmprunt, setAutreEmprunt] = useState();
  const [onClickCapaciteEmprunt, setOnClickCapaciteEmprunt] = useState(false);
  const [capaciteEmprunt, setCapaciteEmprunt] = useState();
  const [dureeCapacite, setDureeCapacite] = useState();
  const [tauxCapacite, setTauxCapacite] = useState();
  const [tauxInteretBanque, setTauxInteretBanque] = useState(3);
  const [loader, setLoader] = useState(false);
  const [fraisNotaire, setFraisNotaire] = useState();
  const [checked, setChecked] = useState(false);
  // const [checkedPrixduBien, setCheckedPrixduBien] = useState(false);
  const [penteCourbe, setPenteCourbe] = useState(14.5264381);
  const [b, setB] = useState(-26801.2783);
  const [gameOver, setGameOver] = useState(false);
  const [PrixDuBien, setPrixDuBien] = useState(0);
  const [checkedAncienNeuf, setCheckedAncienNeuf] = useState('ancien');
  const [onClickInteretCompose, setOnClickInteretCompose] = useState(false);
  const [capitalFinalInteretCompose, setCapitalFinalInteretCompose] = useState();
  const [capitalInitial, setCapitalInitial] = useState();
  const [epargneMensuelle, setEpargneMensuelle] = useState();
  const [horizonPlacement, setHorizonPlacement] = useState();
  const [tauxInteretAnnuel, setTauxInteretAnnuel] = useState();
  const [intervalleVersement, setIntervalleVersement] = useState();
  const [totalVersements, setTotalVersements] = useState();
  const [totalInterets, setTotalInterets] = useState();

  const navigate = useNavigate();

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  const requestCapitalFinalInteretCompose = async () => {
    const response = calculerInteretsComposes(capitalInitial, epargneMensuelle, tauxInteretAnnuel, horizonPlacement, intervalleVersement);
    setCapitalFinalInteretCompose(response.capitalFinal);
    setTotalVersements(response.totalVersements);
    setTotalInterets(response.totalInterets);
    console.log(response);
  };

  const requestFraisDeNotaire = async () => {
    const response = getFraisDeNotaire(PrixDuBien, checkedAncienNeuf);
    setFraisNotaire(response[0]);
  };

  const requestTaux = async () => {
    setLoader(true);
    const response = await requestTauxInteret(year, month);
    if (response && response.status === 200) {
      setTimeout(() => {
        const taux = response.data.dataSets[0].observations['0:0:0:0'][0];
        const precision = taux.toPrecision(2);
        setTauxInteretBanque(precision);
        setLoader(false);
      }, 1000);
    }
    else {
      navigate('/');
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  useEffect(() => {
    requestTaux();
  }, []);

  const mensualiteResult = getMensualite(capital, (taux / 100), (duree * 12));
  const capaciteEmpruntResult = getCapaciteEmprunt(salaire, autreEmprunt, tauxCapacite / 100, dureeCapacite * 12);

  const handleSubmitFormCapaciteEmprumt = (event) => {
    event.preventDefault();
    const resultCapEmp = Math.round(capaciteEmpruntResult);

    if (resultCapEmp < 0) {
      setChecked(false);
      setCapaciteEmprunt('Vous avez dépassé votre capacité d\'emprunt !');
      setGameOver(true);
    }
    else {
      setCapaciteEmprunt(resultCapEmp);
      const resultFrais = ((resultCapEmp - b) / penteCourbe);
      setFraisNotaire(Math.round(resultFrais));
      setChecked(true);
    }
    setOnClickCapaciteEmprunt(true);
    setOnClickPrixDuBien(false);
    setOnClickMensualite(false);
    setOnClickInteretCompose(false);
  };
  const handleChangeChecked = (value) => {
    setChecked(false);
    if (value === 'ancien') {
      setPenteCourbe(14.5264381);
      setB(-26801.2783);
      const result = Math.round(((capaciteEmprunt - (-26801.2783)) / 14.5264381));
      setFraisNotaire(result);
      setChecked(true);
    }
    else if (value === 'neuf') {
      setPenteCourbe(55.8108995);
      setB(-103003.94);

      const result = Math.round(((capaciteEmprunt - (-103003.94)) / 55.8108995));
      setFraisNotaire(result);
      setChecked(true);
    }
  };

  const handleChangePrixDuBienChecked = (value) => {
    setCheckedAncienNeuf(value);
  };
  useEffect(() => {
    requestFraisDeNotaire();
  }, [checkedAncienNeuf]);

  const handleSubmitFormMensualite = (event) => {
    event.preventDefault();
    setOnClickMensualite(true);
    setOnClickPrixDuBien(false);
    setOnClickCapaciteEmprunt(false);
    setOnClickInteretCompose(false);
    setMensualite(Math.round(mensualiteResult));
  };
  const handleSubmitFormPrixDuBien = (event) => {
    event.preventDefault();
    requestFraisDeNotaire();
    setOnClickPrixDuBien(true);
    setOnClickCapaciteEmprunt(false);
    setOnClickInteretCompose(false);
    setOnClickMensualite(false);
  };
  const handleSubmitFormInteretsComposes = (event) => {
    event.preventDefault();
    requestCapitalFinalInteretCompose();
    setOnClickInteretCompose(true);
    setOnClickCapaciteEmprunt(false);
    setOnClickPrixDuBien(false);
    setOnClickMensualite(false);
  };

  const handleChangeCapital = (inputCapital) => {
    setCapital(inputCapital);
    setOnClickMensualite(false);
  };
  const handleChangePrixDuBien = (inputPrixDuBien) => {
    setPrixDuBien(inputPrixDuBien);
    setOnClickPrixDuBien(false);
    // requestFraisDeNotaire();
  };
  const handleChangeTaux = (inputTaux) => {
    setTaux(inputTaux);
    setOnClickMensualite(false);
  };
  const handleChangeDuree = (inputDuree) => {
    setDuree(inputDuree);
    setOnClickMensualite(false);
  };
  const handleChangeSalaire = (inputSalaire) => {
    setSalaire(inputSalaire);
    setOnClickCapaciteEmprunt(false);
  };
  const handleChangeAutreEmprunt = (inputAutreEmprunt) => {
    setAutreEmprunt(inputAutreEmprunt);
    setOnClickCapaciteEmprunt(false);
  };
  const handleChangeTauxCapacite = (inputTauxCapacite) => {
    setTauxCapacite(inputTauxCapacite);
    setOnClickCapaciteEmprunt(false);
  };
  const handleChangeDureeCapacite = (inputDureeCapacite) => {
    setDureeCapacite(inputDureeCapacite);
    setOnClickCapaciteEmprunt(false);
  };
  const handleChangeCapitalInitial = (inputCapitalInitial) => {
    setCapitalInitial(inputCapitalInitial);
    setOnClickInteretCompose(false);
  };
  const handleChangeEpargneMensuelle = (inputEpargneMensuelle) => {
    setEpargneMensuelle(inputEpargneMensuelle);
    setOnClickInteretCompose(false);
  };
  const handleChangeHorizonPlacement = (inputHorizonPlacement) => {
    setHorizonPlacement(inputHorizonPlacement);
    setOnClickInteretCompose(false);
  };
  const handleChangeTauxInteretAnnuel = (inputTauxInteretAnnuel) => {
    setTauxInteretAnnuel(inputTauxInteretAnnuel);
    setOnClickInteretCompose(false);
  };
  const handleChangeIntervalleVersement = (inputIntervalleVersement) => {
    setIntervalleVersement(inputIntervalleVersement);
    setOnClickInteretCompose(false);
  };

  return (
    <div
      className={classnames('calculetteImmo', className)}
      {...rest}
    >
      {loader ? (
        <Loader />
      ) : (
        <>
          <Card sx={{ maxWidth: 345, backgroundColor: '#179488', color: '#FFFFFF' }} className="calculetteImmo-card">
            <form action="" onSubmit={handleSubmitFormMensualite}>
              <CardMedia
                component="img"
                height="240"
                image={pretBanqueImg}
                alt="pret banque"
              />
              <CardContent
                sx={{ color: '#FFFFFF' }}
              >
                <Typography gutterBottom variant="h5" component="div">
                  Patrimoine
                </Typography>
                <Typography gutterBottom variant="h5" component="div" sx={{ color: '#12282A', fontSize: '1em', fontFamily: 'italic' }}>
                  {`Taux OCDE (${month}/${year}) : ${tauxInteretBanque && tauxInteretBanque}%`}
                </Typography>
                <Typography variant="body2" color="#FFFFFF">
                  D'après une somme empruntée, calculez vos mensualités

                </Typography>
                <div className="divFormMensualite">

                  <TextField
                    id="outlined-disabled"
                    required
                    type="number"
                    inputProps={{ min: 10000, step: 1 }}
                    autoComplete="off"
                    label="Capital à emprunter"
                    sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                    onChange={(event) => handleChangeCapital(event.target.value)}
                  />

                  <TextField
                    id="outlined-disabled"
                    required
                    type="number"
                    autoComplete="off"
                    inputProps={{ min: 0, max: 10, step: 0.1 }}
                    label="Taux intérêt en %"
                    sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                    onChange={(event) => handleChangeTaux(event.target.value)}
                  />
                  <TextField
                    id="outlined-disabled"
                    required
                    type="number"
                    autoComplete="off"
                    label="Durée prêt (année)"
                    sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                    onChange={(event) => handleChangeDuree(event.target.value)}
                  />

                </div>
              </CardContent>
              <CardActions className="divMensualite">
                <Button
                  className="myButton"
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{ backgroundColor: '#12282A', '&:hover': { backgroundColor: '#FFFFFF', color: '#12282A' } }}
                >
                  GO !
                </Button>
                {onClickMensualite && (
                <div
                  className="divMensualite-text"
                >
                  <div>
                    MENSUALITES :
                  </div>
                  <div>{mensualite} euros</div>
                </div>
                )}
              </CardActions>
            </form>
          </Card>

          <Card sx={{ maxWidth: 345, backgroundColor: '#179488', color: '#FFFFFF' }} className="calculetteImmo-card">
            <form action="" onSubmit={handleSubmitFormPrixDuBien}>
              <CardMedia
                component="img"
                height="240"
                image={notaireImg}
                alt="pret banque"
              />
              <CardContent
                sx={{ color: '#FFFFFF' }}
              >
                <Typography gutterBottom variant="h5" component="div">
                  Patrimoine
                </Typography>
                <Typography variant="body2" color="#FFFFFF">
                  D'après un prix d'achat, calculez les frais de Notaires

                </Typography>
                <div className="divFormMensualite">
                  <RadioButtonsGroup
                    handleChangeFunction={handleChangePrixDuBienChecked}
                  />

                  <TextField
                    id="outlined-disabled"
                    required
                    type="number"
                    inputProps={{ min: 10000, step: 1 }}
                    autoComplete="off"
                    label="Prix du bien"
                    sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                    onChange={(event) => handleChangePrixDuBien(event.target.value)}
                  />

                </div>
              </CardContent>
              <CardActions className="divMensualite">
                <Button
                  className="myButton"
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{ backgroundColor: '#12282A', '&:hover': { backgroundColor: '#FFFFFF', color: '#12282A' } }}
                >
                  GO !
                </Button>
                {onClickPrixDuBien && (
                  <div
                    className="divMensualite-text"
                  >
                    <div>
                      Frais de Notaires :
                    </div>
                      {fraisNotaire} euros

                  </div>
                )}
              </CardActions>
            </form>
          </Card>

          <Card sx={{ maxWidth: 345, backgroundColor: '#179488', color: '#FFFFFF' }} className="calculetteImmo-card">
            <form action="" onSubmit={handleSubmitFormCapaciteEmprumt}>
              <CardMedia
                component="img"
                height="240"
                image={cup}
                alt="pret banque"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Patrimoine
                </Typography>
                <Typography gutterBottom variant="h5" component="div" sx={{ color: '#12282A', fontSize: '1em', fontFamily: 'italic' }}>
                  {`Taux OCDE (${month}/${year}) : ${tauxInteretBanque && tauxInteretBanque}%`}
                </Typography>
                <Typography variant="body2" color="#FFFFFF">
                  D'après votre salaire, calculez votre capacité d'emprunt

                </Typography>
                <div className="divFormMensualite">
                  <RadioButtonsGroup
                    handleChangeFunction={handleChangeChecked}

                  />

                  <TextField
                    id="outlined-disabled"
                    required
                    type="number"
                    inputProps={{ min: 0, step: 1 }}
                    label="Salaire/mois"
                    autoComplete="off"
                    sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                    onChange={(event) => handleChangeSalaire(event.target.value)}
                  />
                  <TextField
                    id="outlined-disabled"
                    required
                    type="number"
                    inputProps={{ min: 0, step: 1 }}
                    autoComplete="off"
                    label="Autres Emprunts/mois"
                    sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                    onChange={(event) => handleChangeAutreEmprunt(event.target.value)}
                  />
                  <TextField
                    id="outlined-disabled"
                    required
                    type="number"
                    autoComplete="off"
                    inputProps={{ min: 0, step: 0.1 }}
                    label="Taux intérêt en %"
                    sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                    onChange={(event) => handleChangeTauxCapacite(event.target.value)}
                  />
                  <TextField
                    id="outlined-disabled"
                    required
                    type="number"
                    inputProps={{ min: 0, max: 30, step: 1 }}
                    autoComplete="off"
                    label="Durée prêt (année)"
                    sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                    onChange={(event) => handleChangeDureeCapacite(event.target.value)}
                  />

                </div>
              </CardContent>
              <CardActions className="divMensualite">
                <Button
                  className="myButton"
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{ backgroundColor: '#12282A', '&:hover': { backgroundColor: '#FFFFFF', color: '#12282A' } }}
                >
                  GO !
                </Button>
                {onClickCapaciteEmprunt && (
                <div
                  className="divMensualite-text"
                >
                  <div>
                    CAPACITE EMPRUNT :
                  </div>
                  <div>{capaciteEmprunt} {!gameOver && ('euros')} </div>
                  {checked && (
                  <div>N'oubliez pas les frais de Notaire = {fraisNotaire} euros</div>
                  )}

                </div>
                )}
              </CardActions>
            </form>
          </Card>

          <Card sx={{ maxWidth: 345, backgroundColor: '#179488', color: '#FFFFFF' }} className="calculetteImmo-card">
            <form action="" onSubmit={handleSubmitFormInteretsComposes}>
              <CardMedia
                component="img"
                height="240"
                image={interetsComposesImg}
                alt="Interets Composes"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Patrimoine
                </Typography>
                <Typography variant="body2" color="#FFFFFF">
                  Calculez simplement les intérêts composés que vous pouvez générer grâce à vos investissements.

                </Typography>
                <div className="divFormMensualite">

                  <TextField
                    id="outlined-disabled"
                    required
                    type="number"
                    inputProps={{ min: 0, step: 1 }}
                    label="Capital initial"
                    autoComplete="off"
                    sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                    onChange={(event) => handleChangeCapitalInitial(event.target.value)}
                  />
                  <TextField
                    id="outlined-disabled"
                    required
                    type="number"
                    inputProps={{ min: 0, step: 1 }}
                    autoComplete="off"
                    label="Epargne mensuelle"
                    sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                    onChange={(event) => handleChangeEpargneMensuelle(event.target.value)}
                  />
                  <TextField
                    id="outlined-disabled"
                    required
                    type="number"
                    autoComplete="off"
                    inputProps={{ min: 0, max: 100, step: 1 }}
                    label="Horizon de placement"
                    sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                    onChange={(event) => handleChangeHorizonPlacement(event.target.value)}
                  />
                  <TextField
                    id="outlined-disabled"
                    required
                    type="number"
                    inputProps={{ min: 0, max: 50, step: 1 }}
                    autoComplete="off"
                    label="Taux d'intérêt"
                    sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                    onChange={(event) => handleChangeTauxInteretAnnuel(event.target.value)}
                  />
                  <TextField
                    id="outlined-disabled"
                    required
                    type="number"
                    inputProps={{ min: 0, max: 12, step: 1 }}
                    autoComplete="off"
                    label="Intervalle de versement des intérêts"
                    sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                    onChange={(event) => handleChangeIntervalleVersement(event.target.value)}
                  />

                </div>
              </CardContent>
              <CardActions className="divMensualite">
                <Button
                  className="myButton"
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{ backgroundColor: '#12282A', '&:hover': { backgroundColor: '#FFFFFF', color: '#12282A' } }}
                >
                  GO !
                </Button>
                {onClickInteretCompose && (
                  <div
                    className="divMensualite-text"
                  >
                    <div>
                      CAPITAL FINAL :
                    </div>
                    <div>{`${capitalFinalInteretCompose.toFixed(0)} euros`}
                    </div>
                    <div
                      className="divMensualite-text-versement"
                    >
                      Versements : {`${totalVersements.toFixed(0)} euros`}
                    </div>
                    <div
                      className="divMensualite-text-versement"
                    >
                      Interêts : {`${totalInterets.toFixed(0)} euros`}
                    </div>

                  </div>
                )}
              </CardActions>
            </form>
          </Card>
        </>
      )}

    </div>
  );
}

CalculetteImmo.propTypes = {
  className: PropTypes.string,
};
CalculetteImmo.defaultProps = {
  className: '',
};
export default CalculetteImmo;
